<template>
  <div class="hot-point">
    <div class="hot-point-header">{{ hotData[0] }}</div>
    <ul class="hot-point-body">
      <li class="hot-one" @click="$router.push(`/note/${hotData[1].id}?from_id=oly`)">
        <div class="img-box" v-if="hotData[1]">
          <img :src="hotData[1].firstPicture" alt="" />
        </div>
        <div class="text text-long van-multi-ellipsis--l2">{{ hotData[1].title }}</div>
        <div class="text-mask mask"></div>
      </li>
      <li class="hot-two" v-if="hotData.slice(2, 4)">
        <div v-for="(item, index) in hotData.slice(2, 4)" :key="index" class="item" @click="$router.push(`/note/${item.id}?from_id=oly`)">
          <div class="img-box" v-if="item">
            <img :src="item.firstPicture" alt="" />
          </div>
          <div class="text text-short van-multi-ellipsis--l2">{{ item.title }}</div>
          <div class="text-mask mask"></div>
        </div>
      </li>
    </ul>
    <olympic-nav v-if='this.hotData.length > 4' :navName="navName" :index="0"></olympic-nav>
  </div>
</template>

<script>
export default {
  name: 'Hot-Point',
  components: {
    'olympic-nav': () => import(/* webpackChunkName: "hotpoint" */ '../../components/OlympicNav'),
  },
  props: {
    hotData: {
      required: true,
    },
  },
  data() {
    return {
      navName: '这里也有料',
    }
  },
}
</script>

<style scoped lang="less">
.hot-point {
  position: relative;
  height: 519px;
  .hot-point-header {
    z-index: 1;
    position: absolute;
    top: 17px;
    left: -10px;
    box-sizing: border-box;
    padding: 9px 23px 9px 12px;
    height: 36px;
    background: #f7b92b;
    border-radius: 0 54px 54px 0;
    font-size: 20px;
    line-height: 18px;
    font-weight: 400;
    text-align: center;
    color: #ffffff;
    text-shadow: 0px 2px 5px rgba(233, 119, 35, 0.8);
  }
  .hot-point-body {
    display: flex;
    flex-direction: column;
    .img-box {
      background-color: #5397eb;
      height: 100%;
      img {
        width: 100%;
        height: 100%;
        border-radius: 10px;
        object-fit: cover;
      }
    }
    .text-mask {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 136px;
      border-radius: 0 0 10px 10px;
      background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.8));
    }
    .hot-one {
      position: relative;
      width: 100%;
      height: 269px;
      border-radius: 10px;
      margin-bottom: 6px;
      .mask {
        height: 136px;
      }
    }
    .text {
      z-index: 1;
      position: absolute;
      left: 50%;
      transform: translate(-50%, 0);
      font-family: PingFang SC, PingFang SC-Medium;
      font-weight: 500;
      text-align: left;
      color: #ffffff;
    }
    .text-long {
      bottom: 21px;
      width: 316px;
      font-size: 16px;
      line-height: 24px;
    }
    .text-short {
      bottom: 12px;
      width: 154px;
      font-size: 12px;
      line-height: 18px;
    }
    .hot-two {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .item {
        flex-shrink: 0;
        position: relative;
        width: 48%;
        height: 174px;
        border-radius: 10px;
      }
      .mask {
        height: 65px;
      }
    }
  }
}
</style>
