<template>
  <div class='big-shot'>
    <div class='title'>{{bigShotData[0]}}</div>
    <div class='author'>
      <div class='item-author' v-for='item in authors' :key='item.id' @click='gotoDownload'>
        <div class='item'>
          <div
            class='img-author'
            :style="item.avatar ? { background: 'url(' + item.avatar + ') no-repeat center center', 'background-size': 'cover' } : {background: 'url(https://cdn.9kd.com/kdnet/a6593d8447984c8a9fc798ad5c327a1d.png) no-repeat center center', 'background-size': 'cover'}"
          ></div>
          <div class='name-author'>{{item.nickname}}</div>
          <div class='btn'>关注</div>
        </div>
      </div>
    </div>
    <div class='list' v-for='item in bigShotData.slice(1,3)' :key='item.id'>
      <div @click.stop='$router.push("/note/" + item.id + "?from_id=oly")' class='list-img' :style="{ background: 'url(' + item.firstPicture + ') no-repeat center center', 'background-size': 'cover' }">
      </div>
      <div class='list-content'>
        <div class='list-top' @click='gotoDownload'>
          <div class='list-left'>
            <div
              class='list-avatar'
              :style="item.author.avatar ? { background: 'url(' + item.author.avatar + ') no-repeat center center', 'background-size': 'cover' } : {background: 'url(https://cdn.9kd.com/kdnet/a6593d8447984c8a9fc798ad5c327a1d.png) no-repeat center center', 'background-size': 'cover'}"
            ></div>
            <div class='list-author'>
              <p class='name'>{{ item.author.nickname }}</p>
              <p class='time'>{{item.createTime | compareDate}}</p>
            </div>
          </div>
          <div class='btn'>关注</div>
        </div>
        <div @click='$router.push("/note/" + item.id + "?from_id=oly")' class='list-bottom'>{{ item.title }}</div>
      </div>
    </div>
    <olympic-nav v-if='this.bigShotData.length > 3' navName='奥运放大镜' :index='3'></olympic-nav>

  </div>
</template>

<script>
import Bus from '@/utils/bus'

export default {
  name: 'Big-Shot',
  components: {
    'olympic-nav': () => import(/* webpackChunkName: "hotpoint" */ '../../components/OlympicNav'),
  },
  props: {
    bigShotData: {
      required: true
    }
  },
  data() {
    return {
      authors: [],
    }
  },
  created() {
    // 查询专题奥运推荐作者
    this.queryTopicRecommendAuthor()
  },
  methods: {
    // 查询专题奥运推荐作者
    async queryTopicRecommendAuthor() {
      let {data:res} = await this.$api.queryTopicRecommendAuthor()
      if (res.code === 200) {
        this.authors = res.data
      }
    },
    // 去下载或者APP
    gotoDownload() {
      Bus.$emit(Bus.SHOW_OPEN_DIALOG, { show: true })
    }
  }
}
</script>

<style scoped lang='less'>
.big-shot {
  width: 100%;
  position: relative;
  margin-bottom: 32px;
  .title {
    position: absolute;
    left: -10px;
    top: -49px;
    height: 36px;
    line-height: 36px;
    background: #f7b92b;
    border-radius: 0 18px 18px 0;
    box-sizing: border-box;
    padding: 0px 22px 0px 13px;
    font-size: 20px;
    font-weight: 400;
    text-align: center;
    color: #ffffff;
    text-shadow: 0px 2px 5px rgba(233,119,35,0.8);
    z-index: 1;
  }
  .author::-webkit-scrollbar {
    display: none;
  }
  .author {
    position: relative;
    display: flex;
    width: calc(100% + 10px);
    overflow-x: auto;
    margin-top: 60px;
    .item-author {
      flex-shrink: 0;
      display: flex;
      justify-content: center;
      width: 128px;
      height: 168px;
      border-radius: 10px;
      margin-right: 10px;
      background: #fff;
      box-shadow: 0px 0px 18px 0px rgba(55,170,251,0.27) inset;
      .item {
        box-sizing: border-box;
        padding: 22px 0 18px 0;
        .img-author {
          width: 60px;
          height: 60px;
          margin: 0 auto;
          border-radius: 50%;
          background: #ccc;
        }
        .name-author {
          width: 66px;
          margin: 16px auto 14px;
          text-align: center;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .btn {
          width: 66px;
          height: 26px;
          background: #3C5BAE;
          color: #fff;
          text-align: center;
          line-height: 26px;
          border-radius: 13px;
          cursor: pointer;
        }
      }
    }
  }
  .list {
    height: 124px;
    background: #f5fbff;
    border-radius: 10px;
    box-shadow: 0px 0px 18px 0px rgba(55,170,251,0.27) inset;
    margin-top: 11px;
    .list-img {
      float: right;
      width: 124px;
      height: 124px;
      border-radius: 0 10px 10px 0;
      box-shadow: 0px 0px 18px 0px rgba(55,170,251,0.27) inset;
      object-fit: cover;
    }
    .list-content {
      width: 231px;
      height: 124px;
      .list-top {
        width: 204px;
        height: 32px;
        padding: 15px 0px 18px 11px;
        .list-left {
          display: flex;
          float: left;
          .list-avatar {
            width: 32px;
            height: 32px;
            border-radius: 50%;
            margin-right: 8px;
          }
          .list-author {
            p {
              width: 100px;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
            .name {
              font-size: 14px;
              font-weight: 700;
              text-align: left;
              color: #333333;
            }
            .time {
              font-size: 12px;
              text-align: left;
              color: #999999;
            }
          }
        }
        .btn {
          float: right;
          width: 53px;
          height: 22px;
          background: #3C5BAE;
          color: #fff;
          text-align: center;
          line-height: 22px;
          border-radius: 11px;
          margin-top: 5px;
          cursor: pointer;
        }
      }
      .list-bottom {
        width: 204px;
        height: 50px;
        line-height: 24px;
        margin-left: 11px;
        font-weight: 500;
        font-size: 16px;
        font-family: PingFang SC, PingFang SC-Medium;
        text-align: left;
        color: #3c5bae;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
    }
  }
}
</style>
