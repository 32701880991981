<template>
  <div class='gold-more-btn' @click='goOldTab'>
    {{ navName }}
    <p><span class="iconfont ico">&#xe604;</span></p>
  </div>
</template>

<script>
export default {
  name: 'OlympicNav',
  props: {
    navName: {
      required: true
    },
    index: {
      required: true
    }
  },
  methods: {
    goOldTab() {
      this.$store.commit('changeTabIndexValue', this.index)
      this.$router.push(`/tab/${this.$route.params.id}/${this.$route.params.channelId}`)
    },
  }
}
</script>

<style scoped lang='less'>
.gold-more-btn {
  display: flex;
  justify-content: center;
  text-align: center;
  color: #fff;
  font-size: 14px;
  margin-top: 15px;
  p {
    padding-top: 1px;
    padding-left: 4px;
    opacity: 0.8;
    span {
      font-size: 18px;
    }
  }
}
</style>
